import { Box, Card, Grid, Typography, Link, Avatar } from "@mui/material";
import { LoadingSpinner } from "../../shared";
import { HeaderPage } from "../../shared/Header";
import { ProfiloIcon } from "../../../styles/icons";
import { labels } from "../../../labels";
import { useDispatch, useSelector } from "react-redux";
import { getTheme, getVal1Slice, getVal2Slice, savePath, setCodiceMecc, setIndexGestisciProgetti } from "../../../modules/configuration";
import { useNavigate } from "react-router-dom";
import { TableSchedaProfilo } from "../../shared/TableSchedaProfilo";
import { PATH_PROFILO, PATH_SCHEDA_ISTITUTO } from "../../../path";
import { getSchedaProfilo } from "../../../modules/datiOsservatorio";
import { TableSchedaProfiloResponsive } from "../../shared/TableSchedaProfiloResponsive";

const { SCHEDA_UTENTE, BIOGRAFIA, PROFILO_ISTITUTO_APPART, PROFILO_ISTITUTO_RUOLO, MAIL_LABEL, SOCIAL_LABEL } = labels;

export const SchedaProfilo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const valore1slice = useSelector(getVal1Slice);
  const valore2slice = useSelector(getVal2Slice);
  const schedaProfilo = useSelector(getSchedaProfilo);
  let datiSchedaProfilo = schedaProfilo.data;

  //scelgo colori del tema principali in base alla sezione dalla quale arrivo
  let theme = useSelector(getTheme);
  let color1 = theme.color1;
  let color2 = theme.color2;

  return (
    <Box>
      {false ? (
        <Box>
          <LoadingSpinner loading={!getSchedaProfilo.fetchEnded} />
        </Box>
      ) : (
        <Box>
          <HeaderPage resetFiltri={false} linkBack={true} icon={<ProfiloIcon />} title={SCHEDA_UTENTE} color={color1} />
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12}>
                <Card sx={{ padding: "20px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5} md={3}>
                      <Avatar
                        className="AvatarSchedaProfilo"
                        src={datiSchedaProfilo.immagine_profilo}
                        alt="Immagine profilo"
                        sx={{
                          width: "200px",
                          height: "200px",
                          border: "2px solid" + color2,
                          backgroundColor: "white",
                          svg: {
                            color: color1,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7} md={3}>
                      <Typography>
                        {" "}
                        <Card sx={{ padding: "20px" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography sx={{ fontSize: "19px !important", fontWeight: "Bold", cursor: "default" }}>
                                {datiSchedaProfilo.cognome + " " + datiSchedaProfilo.nome}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography className="textTitle">{MAIL_LABEL}</Typography>
                                </Grid>
                                <Grid item xs={12} md={10.5} sm={10}>
                                  <Link
                                    sx={{ cursor: "pointer", fontSize: "18px", wordWrap: "break-word", color: "black", textDecorationColor: "black" }}
                                    onClick={() => window.open(datiSchedaProfilo.length !== 0 ? "mailto:" + datiSchedaProfilo.email : null)}
                                  >
                                    <Typography className="dato">{datiSchedaProfilo.email}</Typography>
                                  </Link>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography className="textTitle">{SOCIAL_LABEL}</Typography>
                                </Grid>
                                <Grid item xs={12} md={9.5} sm={9}>
                                  <Link
                                    sx={{ cursor: "pointer", fontSize: "18px", wordWrap: "break-word", color: "black", textDecorationColor: "black" }}
                                    onClick={() => window.open(datiSchedaProfilo.length !== 0 ? datiSchedaProfilo.link_social : null)}
                                  >
                                    <Typography className="dato">{datiSchedaProfilo.link_social}</Typography>
                                  </Link>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Card sx={{ padding: "20px" }}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography sx={{ fontSize: "25px !important", fontWeight: "Bold", cursor: "default" }}>{BIOGRAFIA}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className="dato">{datiSchedaProfilo.biografia}</Typography>
                          </Grid>
                        </Grid>
                      </Card>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <Card sx={{ padding: "20px" }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Grid item xs={12}>
                              <Typography sx={{ fontSize: "25px !important", fontWeight: "Bold", cursor: "default" }}>
                                {PROFILO_ISTITUTO_APPART}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Link
                                onClick={() => {
                                  dispatch(setIndexGestisciProgetti(0));
                                  dispatch(setCodiceMecc(datiSchedaProfilo.codice_scuola));
                                  navigate(PATH_SCHEDA_ISTITUTO);
                                  dispatch(savePath(PATH_PROFILO));
                                }}
                                sx={{ color: "black !important", textDecorationColor: "black !important", cursor: "pointer" }}
                              >
                                <Typography>{datiSchedaProfilo.nome_scuola}</Typography>
                              </Link>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Grid item xs={12} sm={8}>
                              <Typography className="dato">
                                <Typography sx={{ fontSize: "25px !important", fontWeight: "Bold", cursor: "default" }}>
                                  {PROFILO_ISTITUTO_RUOLO}
                                </Typography>{" "}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Typography className="dato">{datiSchedaProfilo.descr_incarico}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>{" "}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <TableSchedaProfilo
                  valore1slice={valore1slice} //VALORI SLICE PER DECIDERE QUANTI DATI VISUALIZZARE NELLA PAGINA valore2slice={valore2slice}
                  valore2slice={valore2slice}
                  azioni={false}
                  progetti={schedaProfilo.fetchEnded !== false ? datiSchedaProfilo.progetti : null}
                  color2={color2}
                  color1={color1}
                />
                <TableSchedaProfiloResponsive
                  borderColor={color1}
                  color={color2}
                  progetti={schedaProfilo.fetchEnded !== false ? datiSchedaProfilo.progetti : null}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};
