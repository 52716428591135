import { Typography, Grid, InputLabel, ListItemText, FormControl, Select, Checkbox, MenuItem, FormHelperText, Stack } from "@mui/material";
import { ExpandCircleDownOutlined as ExpandCircleDownOutlinedIcon } from "@mui/icons-material/";
import {
  getDatiProgetto,
  getDatiProgettoInnovazione,
  saveValueProgetto,
  saveValueProgettoInn,
  setAzDiffDiss,
  setDocumentazioneOptions,
  setTipoBeneficiari,
} from "../../../modules/configuration";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  MenuListProps: {
    style: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    sx: {
      ".Mui-selected": {
        backgroundColor: "transparent !important",
        ".MuiTypography-root": {
          fontWeight: 600,
        },
      },
    },
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SelectCheckList = ({ tipo, title, placeholder, formik, name, options, errorText }) => {
  const ariaLabel = placeholder.replaceAll(" ", "_").toLowerCase();
  const ariaLabelInputLabel = ariaLabel + "_inputLabel";
  const ariaLabelStack = ariaLabel + "_stack";
  const dispatch = useDispatch();
  let data;
  const datiProg = useSelector(getDatiProgetto);
  const datiProgInn = useSelector(getDatiProgettoInnovazione);
  let opt;
  if (tipo === "curr") {
    data = datiProg;
    opt = options.curricolari;
  }
  if (tipo === "inn") {
    data = datiProgInn;

    if (name === "beneficiari") {
      opt = options;
    } else {
      opt = options.innovazione;
    }
  }
  const handleChange = (value) => {
    if (name === "descr_documentazione") {
      dispatch(setDocumentazioneOptions(value));
    }
    if (name === "azioneDiDiffusioneDisseminazione") {
      dispatch(setAzDiffDiss(value));
    }
    if (name === "beneficiari") {
      dispatch(setTipoBeneficiari(value));
    }
  };
  let valueCheck;
  if (name === "descr_documentazione") {
    valueCheck = data.documentazione.descr_documentazione;
  }
  if (name === "azioneDiDiffusioneDisseminazione") {
    valueCheck = data.documentazione.azioneDiDiffusioneDisseminazione;
  }
  if (name === "beneficiari") {
    valueCheck = data.anagrafica.beneficiari;
  }
  const border = () => {
    if (formik.touched[name] && Boolean(formik.errors[name]) === true) return null;
    else return "none";
  };

  // Fix accessibilità
  useEffect(() => {
    const selects = document.getElementsByClassName("MuiSelect-select");
    if (selects.length > 0) {
      for (let i = 0; i < selects.length; i++) {
        selects[i].removeAttribute("role");
      }
    }
    const stack = document.getElementById(ariaLabelStack);
    if (stack != null) {
      const customSelects = stack.getElementsByClassName("selectChecklist");
      if (customSelects.length > 0)
        for (var i = 0, max = customSelects.length; i < max; i++) {
          const selects = customSelects[i].getElementsByClassName("MuiSelect-select");
          if (selects.length > 0) {
            const nativeSelect = customSelects[i].getElementsByClassName("MuiSelect-nativeInput");
            if (nativeSelect.length > 0)
              for (var k = 0; k < nativeSelect.length; k++) {
                nativeSelect[k].setAttribute("id", ariaLabel);
                nativeSelect[k].setAttribute("aria-label", ariaLabel);
              }
          }
        }
    }
    const label = document.getElementById(ariaLabelInputLabel);
    if (label != null) label.setAttribute("for", ariaLabel);

    const uls = document.getElementsByTagName("ul");
    if (uls.length > 0) {
      for (let i = 0; i < uls.length; i++) {
        uls[i].removeAttribute("role");
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid item sx={{ marginBottom: "20px" }}>
      <Typography sx={{ fontSize: "16px !important ", fontWeight: "Bold" }}>{title}</Typography>
      <FormControl sx={{ marginTop: 1, width: "100%" }}>
        <InputLabel
          id={ariaLabelInputLabel}
          size="small"
          sx={{
            transform: "none",
            transition: "none",
            animation: "none",
            color: "#595959 !important",
          }}
        >
          <Typography
            sx={{
              marginBottom: "6px",
              marginLeft: "15px",
              fontWeight: "none",
              fontSize: "16px",
              opacity: 1,
              ".MuiInputLabel-root": {
                color: "#595959",
              },
            }}
          >
            {name === "beneficiari" && formik.values[name].length > 0 ? "Beneficiari inseriti" : placeholder}
          </Typography>
        </InputLabel>
        <Stack id={ariaLabelStack}>
          <Select
            multiple
            name={name}
            size="small"
            value={valueCheck !== undefined ? valueCheck.map((opt) => opt) : null}
            onChange={(e) => {
              let selected = e.target.value;

              if (name === "descr_documentazione" && tipo === "curr" && selected[selected.length - 1] === 8) {
                selected = [8];
              } else {
                if (name === "descr_documentazione" && tipo === "curr") selected = selected.filter((e) => e !== 8);
              }
              if (name === "azioneDiDiffusioneDisseminazione" && tipo === "curr" && selected[selected.length - 1] === 6) {
                selected = [6];
              } else {
                if (name === "azioneDiDiffusioneDisseminazione" && tipo === "curr") selected = selected.filter((e) => e !== 6);
              }
              if (name === "descr_documentazione" && tipo === "inn" && selected[selected.length - 1] === 10) {
                selected = [10];
              } else {
                if (name === "descr_documentazione" && tipo === "inn") selected = selected.filter((e) => e !== 10);
              }
              if (name === "azioneDiDiffusioneDisseminazione" && tipo === "inn" && selected[selected.length - 1] === 8) {
                selected = [8];
              } else {
                if (name === "azioneDiDiffusioneDisseminazione" && tipo === "inn") selected = selected.filter((e) => e !== 8);
              }
              name === "beneficiari"
                ? dispatch(
                    saveValueProgettoInn({
                      fieldSection: "anagrafica",
                      fieldName: name,
                      fieldValue: selected,
                    })
                  )
                : tipo === "inn"
                  ? dispatch(
                      saveValueProgettoInn({
                        fieldSection: "documentazione",
                        fieldName: name,
                        fieldValue: selected,
                      })
                    )
                  : dispatch(
                      saveValueProgetto({
                        fieldSection: "documentazione",
                        fieldName: name,
                        fieldValue: selected,
                      })
                    );
              handleChange(selected);
              formik.handleChange(e);
            }}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            renderValue={() => ""}
            MenuProps={MenuProps}
            IconComponent={ExpandCircleDownOutlinedIcon}
            sx={{
              marginTop: "-8px",
              width: "100%",
              border: "none",
              boxShadow: "0px 3px 6px #000000C7",
              borderRadius: "5px",
              opacity: "1",
              color: "black !important",
              ".MuiOutlinedInput-notchedOutline": {
                border: border(formik, name),
              },
              ".MuiSelect-icon": {
                color: "black",
              },
              ".MuiButtonBase-root-MuiMenuItem-root": {
                opacity: 1,
              },
            }}
          >
            {opt?.map((option, index) => (
              <MenuItem key={index} value={option.id}>
                <Checkbox
                  checked={valueCheck !== undefined ? valueCheck.map((opt) => opt).indexOf(option.id) > -1 : null}
                  sx={{
                    color: "black !important",
                  }}
                  inputProps={{
                    "aria-label": option.descrizione,
                  }}
                />
                <ListItemText
                  primary={option.descrizione}
                  sx={{
                    color: "black !important",
                    whiteSpace: "break-spaces",
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </FormControl>
      {formik.touched[name] && Boolean(formik.errors[name]) && errorText && (
        <FormHelperText sx={{ color: "#d32f2f", marginLeft: "15px" }}>{errorText}</FormHelperText>
      )}
    </Grid>
  );
};
