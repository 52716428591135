import { useState, useEffect } from "react";
import { Autocomplete, Box, List, FormControl, TextField, Typography, Checkbox, IconButton } from "@mui/material";
import { ExpandCircleDownOutlined as ExpandCircleDownOutlinedIcon } from "@mui/icons-material";
import { labels } from "../../labels";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { deleteOptionSelect, getDatiProgetto, getOptionSelect, saveOptionSelect, saveValueProgetto } from "../../modules/configuration";
import { OptionsCheckModale } from "./OptionsCheckModale";
import { getDisciplineCoinvolte } from "../../modules/datiOsservatorio";
import { CancelOutlined as CancelOutlinedIcon } from "@mui/icons-material";

const { APRI, NO_DATA, PLACEHOLDER_DISCIPLINE_COINVOLTE, DISCIPLINE_COINVOLTE } = labels;

export const CheckboxesTags = ({ value, name, required, error, errorText, formik, disabled, filterOptions }) => {
  const ariaLabel = PLACEHOLDER_DISCIPLINE_COINVOLTE.replaceAll(" ", "_").toLowerCase();
  let placeholder = PLACEHOLDER_DISCIPLINE_COINVOLTE;
  let label = DISCIPLINE_COINVOLTE;
  const [hasFocus, setHasFocus] = useState(false);
  const disciplineCoinvolte = useSelector(getDisciplineCoinvolte);
  const dispatch = useDispatch();
  const datiProg = useSelector(getDatiProgetto);
  const optionsSelected = useSelector(getOptionSelect);
  let optionsCheckList = disciplineCoinvolte.data;
  const [inputText, setInputText] = useState("");

  const handleChange = (value) => {
    dispatch(saveOptionSelect(value));
  };

  // Fix accessibilità
  useEffect(() => {
    const autocomplete = document.getElementsByClassName("MuiAutocomplete-input");
    if (autocomplete.length > 0) {
      for (let i = 0; i < autocomplete.length; i++) {
        autocomplete[i].removeAttribute("role");
      }
    }
    const input = document.getElementById(ariaLabel);
    if (input != null) input.setAttribute("aria-label", ariaLabel);
    // eslint-disable-next-line
  }, []);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <Typography className="fieldLabel">
        {label}
        {required && " *"}
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: "20px" }}>
        <Autocomplete
          disabled={disabled}
          disableCloseOnSelect
          multiple
          id="checkboxes-tags-demo"
          disablePortal
          options={optionsCheckList}
          size="small"
          {...(label != null && { id: ariaLabel })}
          openText={APRI}
          noOptionsText={NO_DATA}
          renderTags={() => null}
          inputValue={inputText}
          onInputChange={(_, value, reason) => {
            if (reason === "reset") return;

            setInputText(value);
          }}
          clearIcon={
            <IconButton title="Cancella" edge="end" onClick={() => setInputText("")}>
              <CancelOutlinedIcon sx={{ color: "black" }} />
            </IconButton>
          }
          popupIcon={<ExpandCircleDownOutlinedIcon {...(disabled == null && { sx: { color: error ? "#d32f2f" : "black !important" } })} />}
          renderInput={(params) => (
            <TextField
              {...(error && { sx: { "& input": { color: "#d32f2f" } } })}
              {...params}
              required={required}
              aria-label={ariaLabel}
              error={error}
              helperText={errorText}
              {...(!hasFocus && !value && { label: placeholder })}
            />
          )}
          onChange={(e) => {
            const selected = e.target.outerText;
            let optionsDescr = datiProg.contesto.discipline.length === 0 ? [] : datiProg.contesto.discipline;
            let optionsDescriptions = [...optionsDescr];

            for (let i = 0; i < optionsCheckList.length; i++) {
              if (selected === optionsCheckList[i].descrizione) {
                if (optionsDescriptions.includes(optionsCheckList[i].id)) {
                  optionsDescriptions = optionsDescriptions.filter((e) => e !== optionsCheckList[i].id);
                } else {
                  optionsDescriptions.push(optionsCheckList[i].id);
                }
              }
            }
            dispatch(
              saveValueProgetto({
                fieldSection: "contesto",
                fieldName: name,
                fieldValue: optionsDescriptions,
              })
            );

            handleChange(optionsDescriptions);
            formik.setFieldValue(optionsDescriptions, "discipline");
          }}
          name={name}
          error={formik.touched[name] && Boolean(formik.errors[name])}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          {...(filterOptions != null && { filterOptions: filterOptions })}
          getOptionLabel={(opt) => opt.descrizione}
          renderOption={(props, option, { selected }) => {
            let newProps = Object.assign({}, props);
            newProps.key = option.id;
            return (
              <Box component="li" {...newProps} {...(option.id == null && { sx: { fontStyle: "oblique", fontWeight: 600 } })}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  sx={{ marginRight: 0, color: "black !important" }}
                  checked={datiProg.contesto.discipline !== undefined ? datiProg.contesto.discipline.indexOf(option.id) > -1 : null}
                />
                {option.descrizione}
              </Box>
            );
          }}
        />
        <Box className="center">
          {optionsSelected.length !== 0 ? (
            <List
              sx={{
                marginTop: "40px",
                overflow: "auto",
                maxWidth: "800px",
                maxHeight: "300px",
                backgroundColor: "#F0F0F0",
                borderRadius: "20px",
                "@media (max-width:480px)": {
                  width: "270px",
                  height: "400px",
                },
              }}
            >
              {optionsSelected[0] !== undefined
                ? optionsSelected[0].map((elemento, index) =>
                    optionsCheckList.map((e) =>
                      elemento === e.id ? (
                        <Box sx={{ marginLeft: "10px" }}>
                          <OptionsCheckModale
                            descrCampo={null}
                            color={"white"}
                            border={"solid 3px #FABE10"}
                            width={"98%"}
                            elemento={e.descrizione}
                            index={index}
                            formik={formik}
                            click={(e) => {
                              dispatch(deleteOptionSelect(elemento));
                            }}
                          />
                        </Box>
                      ) : null
                    )
                  )
                : null}
            </List>
          ) : null}
        </Box>
      </FormControl>
    </>
  );
};
