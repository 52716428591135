import { MainTableProgetti } from "../MainTableProgetti";
import { Box } from "@mui/system";
import { HeaderProgetti } from "../HeaderProgetti";
import { useDispatch, useSelector } from "react-redux";
import { aggiornaValoriSlice, getFilter, getFiltriProgettiAttivita, getfiltri } from "../../../../modules/configuration";
import { PATH_HOME, PATH_PROGETTI_CURRICOLARI_O_EXTRA_CURRICOLARI, PATH_RICERCA_CURRICOLARE_EXTRA_CURRICOLARE } from "../../../../path";
import { TableResponsive } from "../../../shared/TableResponsive";
import { getProgettiCurricolariExtraCurricolari } from "../../../../modules/datiProgetti";
import { PaginationShared } from "../../../shared/Pagination";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "../../gestisciProgetti/TableProgetti";
import { labels } from "../../../../labels";
import { LoadingSpinner } from "../../../shared";
import { Typography } from "@mui/material";

const { PROGETTI_CURRICOLARE, AVVISO_RISULTATO_RICERCA } = labels;

const TableResponsiveCampi = ["Completamento", "Tipologia", "Titolo", "Data inserimento", "Referente", "Pubblicazione"];

export const ProgettiCurrExtraCurr = () => {
  const options = useSelector(getfiltri);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const progCurricolari = useSelector(getProgettiCurricolariExtraCurricolari);

  //REDIRECT IN CASO IN CUI CHIAMATA PER I DATI DEGLI ISTITUTI NON ANDASSE A BUON FINE
  if (progCurricolari.fetchEnded === false) {
    navigate(PATH_HOME);
  }
  let prog = progCurricolari.data;
  const sottoAtt = useSelector(getFiltriProgettiAttivita);

  const checkProgCurr = (prog) => {
    const checkCurricolare = prog.id_tipo_progetto === 1 || prog.id_tipo_progetto === 3;
    const checkStato = prog.id_stato_progetto !== 1;
    return checkCurricolare && checkStato;
  };

  let data = prog.filter(checkProgCurr);

  const filtri = useSelector(getfiltri);
  const filter = useSelector(getFilter);
  const [page, setPage] = useState(1);

  function handlechange(e, value) {
    dispatch(aggiornaValoriSlice(value));
    setPage(value);
  }

  //filtro sulle attivita
  const checkAttivitaFunz = (attivita) => {
    if (sottoAtt.length === undefined) {
      return true;
    } else {
      let check = 0;
      for (let i = 0; i < sottoAtt.length; i++) {
        for (let j = 0; j < attivita.length; j++) {
          if (
            sottoAtt[i].finalita + sottoAtt[i].descr_valore + sottoAtt[i].descr_campo ===
            (attivita[j].obbligatorieta === 1 ? "*" : "") + attivita[j].finalita + attivita[j].descr_valore + attivita[j].descr_campo
          ) {
            check = check + 1;
          }
        }
      }
      if (check === sottoAtt.length) {
        return true;
      } else return false;
    }
  };

  //controllo sulle discipline
  const checkdisciplineFunz = (discipline) => {
    for (let i = 0; i < discipline.length; i++) {
      if (discipline[i].descr_disciplina === filtri.discipline) {
        return true;
      }
    }
    return false;
  };

  //controllo sulla documentazione
  const checkDoc = (data) => {
    for (let i = 0; i < data.documentazione.length; i++) {
      if (data.documentazione[i].descr_documentazione === filtri.documentazione) {
        return true;
      }
    }
    return false;
  };

  const checkFiltriProgCurr = (data) => {
    const checkTipologia = filtri.tipologia === "" ? true : data.descr_t_progetto === filtri.tipologia;
    const checkDataInizio = filtri.dataInizio === "" ? true : format(new Date(data.data_inizio), "yyyy-MM-dd") === filtri.dataInizio;
    const checkOrdineScuola = filtri.ordineScuola === "" ? true : data.descr_grado_scuola === filtri.ordineScuola;
    const checkComposizioneStudenti = filtri.composizioneStudenti === "" ? true : data.descr_composizione_gruppo === filtri.composizioneStudenti;
    const checkCodiceMeccanografico = filtri.codiceMeccanografico === "" ? true : data.codice_scuola === filtri.codiceMeccanografico;
    const checkTitolo = filtri.titolo === "" ? true : data.titolo_progetto === filtri.titolo;
    const checkDataInserimento =
      filtri.dataInserimento === "" ? true : format(new Date(data.data_inserimento), "yyyy-MM-dd") === filtri.dataInserimento;
    const checkInterdisciplinarietà = filtri.interdisciplinarieta === "" ? true : data.interdisciplinarieta === filtri.interdisciplinarieta;
    const checkPresenzaStudentiBes = filtri.presenzaStudentiBes === "" ? true : data.descr_studenti_bes === filtri.presenzaStudentiBes;
    const checkProvincia = filtri.provincia === "" ? true : data.provincia === filtri.provincia;
    const checkAbstract = filtri.abstract === "" ? true : data.descr_progetto !== null ? data.descr_progetto.includes(filtri.abstract) : false;
    const checkAnnoRealizzazione = filtri.annoRealizzazione === "" ? true : data.descr_anno_realiz === filtri.annoRealizzazione;
    const checkTemaPrevalente = filtri.temaPrevalente === "" ? true : data.descr_tipo_attributo === filtri.temaPrevalente;
    const checkContestoAccompagnamento =
      filtri.contestoAccompagnamento === "" ? true : data.descr_contesto_accompagnamento === filtri.contestoAccompagnamento;
    const checkdiscipline = filtri.discipline === "" ? true : checkdisciplineFunz(data.discipline);
    const checkDenominazione = filtri.denominazione === "" ? true : data.nomeScuola === filtri.denominazione;
    const checkTempoRealizzazione = filtri.tempoRealizzazione === "" ? true : data.descr_tempo_realiz === filtri.tempoRealizzazione;
    const checkattivitaClil = filtri.attivitaClil === "" ? true : data.descr_attivita_clil === filtri.attivitaClil;
    const checkReferente = filtri.referente === "" ? true : data.referente_cogn + " " + data.referente_nome === filtri.referente;
    const checkPubblicato = filtri.pubblicato === "" ? true : data.descr_stato_progetto === filtri.pubblicato;
    const checkDocumentato =
      filtri.documentato === "" ? true : "SI" === filtri.documentato ? data.link_documentazione !== null : data.link_documentazione === null;
    const checkfinalita = filtri.attivita === "" ? true : checkAttivitaFunz(data.attivita);
    const checkcoReferenti = filtri.coReferenti === "" ? true : data.coReferente_cognome + " " + data.coReferente_nome === filtri.coReferenti;
    const checkDocumentazione = filtri.documentazione === "" ? true : checkDoc(data);
    const checkValutazione = filtri.valutazione === "" ? true : data.descr_valutazione === filtri.valutazione;

    return (
      checkTipologia &&
      checkDataInizio &&
      checkOrdineScuola &&
      checkComposizioneStudenti &&
      checkCodiceMeccanografico &&
      checkTitolo &&
      checkDataInserimento &&
      checkInterdisciplinarietà &&
      checkPresenzaStudentiBes &&
      checkProvincia &&
      checkAbstract &&
      checkAnnoRealizzazione &&
      checkTemaPrevalente &&
      checkContestoAccompagnamento &&
      checkdiscipline &&
      checkDenominazione &&
      checkTempoRealizzazione &&
      checkattivitaClil &&
      checkReferente &&
      checkPubblicato &&
      checkDocumentato &&
      checkfinalita &&
      checkDocumentazione &&
      checkValutazione &&
      checkcoReferenti
    );
  };

  data = filter === true ? data.filter(checkFiltriProgCurr) : data;

  return (
    <Box className="ricerca">
      <HeaderProgetti title={PROGETTI_CURRICOLARE} location={PATH_RICERCA_CURRICOLARE_EXTRA_CURRICOLARE} options={options} />
      <Box>
        <MainTableProgetti progetti={data} path={PATH_PROGETTI_CURRICOLARI_O_EXTRA_CURRICOLARI} />
        <TableResponsive
          borderColor={"#8E2155"}
          color={"#DEA4BD"}
          table={TableResponsiveCampi}
          progetti={data}
          path={PATH_PROGETTI_CURRICOLARI_O_EXTRA_CURRICOLARI}
        />
      </Box>
      {data.length === 0 && progCurricolari.fetchEnded === true ? (
        <Box className="center" sx={{ marginTop: "50px" }}>
          <Typography sx={{ fontSize: "22px", fontWeight: "Bold" }}>{AVVISO_RISULTATO_RICERCA}</Typography>
        </Box>
      ) : null}
      <Box className="center">
        {Math.ceil(data.length / 5) > 1 ? (
          <PaginationShared page={page} count={Math.ceil(data.length / 5)} handlechange={handlechange} color={"#8E2155 !important"} />
        ) : null}
      </Box>
      <LoadingSpinner loading={!progCurricolari.fetchEnded} />
    </Box>
  );
};
